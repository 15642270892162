import { useSupabaseClient } from "@supabase/auth-helpers-react";
import Image from "next/image";
import Link from "next/link";
import { En, Fa } from "@/components/ui/multilang";
import StarRating from "@/components/ui/StarRating";
import persianJs from "persianjs";

import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getSortedRowModel,
  FilterFn,
  createColumnHelper,
} from "@tanstack/react-table";
import { rankItem } from "@tanstack/match-sorter-utils";

import Icon from "@/components/ui/Icon";

type Profile = {
  profileId: string;
  categoryName: string;
  categoryNameFa: string;
  teacherFullName: string;
  teacherFullNameFa: string;
  topicName: string;
  topicNameFa: string;
  englishFluency: string;
  englishFluencyFa: string;
  price: number;
  rating: number;
  status: string | null;
};

export default function HeroSearchResultsGrid({
  data,
  searchTerm,
  setSearchTerm,
}: {
  data: any[];
  searchTerm: any;
  setSearchTerm: any;
}) {
  const columnHelper = createColumnHelper<Profile>();

  const columns = [
    columnHelper.accessor("profileId", { enableGlobalFilter: false }),
    columnHelper.accessor("categoryName", {}),
    columnHelper.accessor("categoryNameFa", {}),
    columnHelper.accessor("teacherFullName", {}),
    columnHelper.accessor("teacherFullNameFa", {}),
    columnHelper.accessor("topicName", {}),
    columnHelper.accessor("topicNameFa", {}),
    columnHelper.accessor("price", { enableGlobalFilter: false }),
    columnHelper.accessor("rating", { enableGlobalFilter: false }),
  ];

  const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
    // Rank the item
    const itemRank = rankItem(row.getValue(columnId), value);

    // Store the itemRank info
    addMeta({
      itemRank,
    });

    // Return if the item should be filtered in/out
    return itemRank.passed;
  };

  const table = useReactTable({
    data,
    columns,
    state: {
      globalFilter: searchTerm,
    },
    onGlobalFilterChange: setSearchTerm,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    debugTable: true,
    debugHeaders: true,
    debugColumns: false,
  });

  return (
    <div className="py-2 lt-sm:max-table-width-in-mobile sm:max-w-main relative flex flex-col h-full   ">
      <div className="h-full">
        {table.getRowModel().rows.length === 0 && (
          <div className="flex justify-center items-center h-full gap-1">
            <Icon name="bf-i-ph-smiley-sad" className="text-lg" />
            <p>
              <En>No Result Found.</En>
              <Fa>نتیجه‌ای یافت نشد.</Fa>
            </p>
          </div>
        )}
        <ul
          className="grid gap-6 "
          style={{ gridTemplateColumns: "repeat(auto-fill, minmax(min(15rem , 100%), 1fr ))" }}
        >
          {table.getRowModel().rows.map((row, index) => {
            return (
              <ClassCardForTableHook
                key={row.getValue("profileId")}
                profileId={row.getValue("profileId")}
                topicName={row.getValue("topicName")}
                topicNameFa={row.getValue("topicNameFa")}
                teacherFullName={row.getValue("teacherFullName")}
                teacherFullNameFa={row.getValue("teacherFullNameFa")}
                price={row.getValue("price")}
                rating={row.getValue("rating")}
                categoryName={row.getValue("categoryName")}
              />
            );
          })}
        </ul>
      </div>
    </div>
  );
}

function ClassCardForTableHook({
  profileId,
  topicName,
  topicNameFa,
  teacherFullName,
  teacherFullNameFa,
  price,
  rating,
  categoryName,
}) {
  const supabase = useSupabaseClient();
  const imageUrl = supabase.storage.from("teacher").getPublicUrl(`photo/${profileId}`);

  return (
    <li key={profileId} className="">
      <Link
        href={`/tutors/${teacherFullName.toLowerCase().replaceAll(" ", "-")}-${profileId}`}
        target="_blank"
        className="flex gap-3 "
      >
        <div className="grid relative isolate w-20 h-20 shrink-0">
          <Image
            src={imageUrl.data.publicUrl}
            width={80}
            height={80}
            alt="avatar"
            className="rd-xl bg-gray5 object-cover aspect-ratio-1/1 w-full"
            loading="lazy"
          />
        </div>
        <div>
          <h3 className=" gap-1 line-clamp-1">
            <span className="text-sm opacity-50 mie-1 inline-flex items-baseline">
              <CategoryIcon categoryName={categoryName} />
            </span>
            <Fa>
              <span className="c-sand11  fw-900 leading-5">{topicNameFa}</span>
            </Fa>
            <En>
              <span className="c-sand11  fw-600 leading-4">{topicName}</span>
            </En>
          </h3>
          <p className="c-sand11 text-xs">
            <Fa>{teacherFullNameFa}</Fa>
            <En>{teacherFullName}</En>
          </p>
          <p className="c-sand11 text-xs">
            <En>{price} CAD/session</En>
            <Fa>
              {persianJs(String(price)).persianNumber().toString()}
              {` `}
              دلار کانادا /جلسه
            </Fa>
          </p>
          <div className="text-2xs">
            <StarRating number={rating} />
          </div>
        </div>
      </Link>
    </li>
  );
}

function CategoryIcon({ categoryName }) {
  return (
    <>
      {categoryName.toLowerCase() === "art" && <Icon name="bf-i-ph-palette" subdued={false} />}
      {categoryName.toLowerCase() === "music" && <Icon name="bf-i-ph-music-note" subdued={false} />}
      {categoryName.toLowerCase() === "programming" && <Icon name="bf-i-ph-code" subdued={false} />}
      {categoryName.toLowerCase() === "farsi" && <Icon name="bf-i-ph-book-open-text" subdued={false} />}
      {categoryName.toLowerCase() === "language" && <Icon name="bf-i-ph-translate" subdued={false} />}
      {categoryName.toLowerCase() === "sports" && <Icon name="bf-i-ph-football" subdued={false} />}
      {categoryName.toLowerCase() === "science and math" && <Icon name="bf-i-ph-atom" subdued={false} />}
      {categoryName.toLowerCase() === "chess" && <Icon name="bf-i-ph-horse" subdued={false} />}
      {categoryName.toLowerCase() === "pernosal development" && <Icon name="bf-i-ph-flower-lotus" subdued={false} />}
      {categoryName.toLowerCase() === "applications" && <Icon name="bf-i-ph-app-window" subdued={false} />}
    </>
  );
}
