import { useRouter } from "next/router";
import { En, Fa } from "@/components/ui/multilang";
import BluredCircle from "./BluredCircle";
import Space from "../ui/Space";

export default function Testomonials() {
  return (
    <section className="relative">
      <BluredCircle radius={200} top="80%" left="50%" bg="bg-brand-light-blue" blur="200px" opacity={0.5} />
      <div className="p-4">
        <div className="mx-auto max-w-70rem relative">
          <BluredCircle radius={200} top="100%" left="5%" bg="bg-brand-orange" blur="200px" opacity={0.06} />
          <En>
            <h2 className="H2 c-title ">What students say about our tutors</h2>
          </En>
          <Fa>
            <h2 className="H2 c-title ">کاربران درسون درباره‌ی معلم‌های ما چه می‌گویند؟</h2>
          </Fa>
          <Space size="h-12" />
        </div>
      </div>
      <div className="lg:p-4">
        <div className="blcok lg:display-none">
          <div className="">
            <ul
              className="grid grid-auto-flow-col items-stretch gap-6  overflow-x-auto pb-4 w-full   "
              style={{
                gridAutoColumns: "max(40% , 20rem)",
              }}
            >
              <Testomonial0 />
              <Testomonial2 />
              <Testomonial3 />
              <Testomonial4 />
              <Testomonial5 />
            </ul>
          </div>
        </div>
        <div className="display-none lg:block">
          <div className="mx-auto max-w-70rem">
            <ul className="gap-4 pb-4 w-full flex " style={{ gridTemplateColumns: "2fr 3fr" }}>
              <div className=" flex-basis-35%">
                <Testomonial1 />
              </div>
              <div className="flex-basis-65% grid gap-4">
                <div className="grid gap-4  " style={{ gridTemplateColumns: "2fr 3fr " }}>
                  <Testomonial2 />
                  <Testomonial3 />
                </div>
                <div className="grid gap-4" style={{ gridTemplateColumns: "3fr 2fr" }}>
                  <Testomonial4 />
                  <Testomonial5 />
                </div>
              </div>
            </ul>
          </div>
        </div>
      </div>

      <div className="p-4">
        <div className="mx-auto max-w-70rem ">{/* Other Contents */}</div>
      </div>
    </section>
  );
}

function TestomonialCard({
  children,
  name,
  gridRow,
  gridColumn,
  gradientFrom,
  gradientTo,
  bg,
  color,
  subject,
  from,
  className = "",
}) {
  return (
    <li className={` grid  h-full  ${className}  `} style={{ gridTemplateRows: "1fr auto" }}>
      <MessageBubble bg={bg} color={color} gradientFrom={gradientFrom} gradientTo={gradientTo}>
        {children}
      </MessageBubble>
      <div className="mt-1 grid  gap-1 items-center leading-4 ">
        <p className="fw-500 text-xl tracking-tight font-display c-melow leading-5">{name}</p>
        <div className="text-xs c-subdued leading-3.5 c-sand11 flex">
          <p>
            <Fa>کلاس</Fa>
            {` `}
            {subject}
            {` `}
            <En>class</En>
          </p>
          <p>
            <En>, from</En>
            <Fa>، از</Fa>
            {` `}
            {from}
          </p>
        </div>
      </div>
    </li>
  );
}

function MessageBubble({ children, bg, color, gradientFrom, gradientTo }) {
  const { locale } = useRouter();
  return (
    <div
      className="h-full grid  "
      style={{
        gridTemplateRows: "1fr auto",
      }}
    >
      <div
        className={`   rd-xl relative   `}
        style={{
          backgroundImage: "url('/static/noise.svg')",
          backgroundSize: "auto",
          backgroundRepeat: "repeat",
          backdropFilter: "blur(10px)",
        }}
      >
        <p
          className={`rd-xl tracking-wide grid h-full p-6 bg-gradient-to-b ${gradientFrom} ${gradientTo} `}
          style={{ gridTemplateRows: "1fr" }}
        >
          <span className="self-center line-height-0.9 c-sandA-12 lt-lg:text-sm ">{children}</span>
        </p>
      </div>
      <div className={`flex px-5 ${locale === "fa" && "scale-x--100 justify-end "}`}>
        <Triangle color={color} bg={bg} />
      </div>
    </div>
  );
}

function Triangle({ bg, color }) {
  return <div className={`triangle -mt-2  scale-x--100 ${bg} ${color}`}></div>;
}

function Testomonial1() {
  const { locale } = useRouter();

  return (
    <TestomonialCard
      name={locale === "en" ? "Ghazal" : "سحر"}
      gridRow="1/5"
      gridColumn="1/10"
      bg="bg-[#FEDBC2]"
      gradientTo="to-[#FEDBC2]"
      gradientFrom="from-[#FEDBC2]/70"
      color="c-[#FEDBC2]"
      from={locale === "en" ? "Australia" : "تورنتو"}
      subject={locale === "en" ? "Chess" : "شطرنج"}
      className="lt-lg:pis-4  "
    >
      <span className=" lg:text-lg">
        <En>
          We are very happy with the Chess classes. He not only teaches my son Chess but also encourages him and gives
          him confidence. I think his approach to teaching is effective and valuable.{" "}
          <strong>
            He is always happy to communicate about my {`son’s`} progress and what he needs to do to improve.
          </strong>{" "}
          He is flexible with timing and is always very understanding. He sends us a reminder a day before each class
          which is great for adjusting time and plans. We really appreciate his efforts. Thank you!
        </En>
        <Fa>
          ایشون بسیار معلم باهوش، باحوصله، متعهد به کار و باسوادی در شطرنج هستن.{" "}
          <strong>رابطه بسیار دوستانه و‌ درعین‌حال حرفه‌ای با پسر ۱۳ ساله من سر جلسه‌ها داشتن</strong> و همین باعث
          علاقه‌مندی بیشتر پسرم به شطرنج شده. با جملات تشویقی و‌انگیزشی سر کلاس، باعث‌ می‌شدن‌ که کلاس یکنواخت نباشه و
          اصلاً پسرم گذر زمان رو متوجه نمی‌شد. بعد از هر جلسه تمرین می‌فرستادن و‌ همیشه به من فیدبک می‌دادن. با تشکر از
          ایشون و‌ براشون آرزوی موفقیت دارم .
        </Fa>
      </span>
    </TestomonialCard>
  );
}

function Testomonial0() {
  const { locale } = useRouter();

  return (
    <TestomonialCard
      name={locale === "en" ? "Mojgan" : "سحر"}
      gridRow="1/5"
      gridColumn="1/10"
      bg="bg-[#FEDBC2]"
      gradientTo="to-[#FEDBC2]"
      gradientFrom="from-[#FEDBC2]/70"
      color="c-[#FEDBC2]"
      from={locale === "en" ? "United States" : "ویرجینیای شمالی"}
      subject={locale === "en" ? "Violin" : "فارسی"}
      className="lt-lg:pis-4 "
    >
      <En>
        He is a great violin teacher, professional, and passionate about his work.
        <br />
        I’m happy to see that my son enjoys playing violin and never takes it as a burden. I highly recommend him to
        everyone.
      </En>
      <Fa>
        پسر من که ده ساله هست و هیچ علاقه‌ای به زبان فارسی نشان نمی‌داد بعد از مدت کوتاهی که از کلاس‌هاش گذشت از من
        فارسی کلمات را می‌پرسه و شروع به ساختن جمله‌های کوتاه فارسی کرده است.
      </Fa>
    </TestomonialCard>
  );
}

function Testomonial2() {
  const { locale } = useRouter();

  return (
    <TestomonialCard
      name={locale === "en" ? "Atefeh" : "بهناز"}
      gridRow="1/3"
      gridColumn="10/22"
      gradientTo="to-brand-light-green"
      gradientFrom="from-brand-light-blue/70"
      bg="bg-brand-light-green"
      color="c-brand-light-green"
      from={locale === "en" ? "United States" : "تورنتو"}
      subject={locale === "en" ? "Piano" : "ریاضی"}
    >
      <En>
        She is wonderful. She is so flexible and easy to work with.{" "}
        <strong>Her fluency in both Farsi and English makes it really easy for the kids to understand her.</strong> In
        general, she is a 5-star teacher.
      </En>
      <Fa>
        ايشون بسيار معلم با سواد و باحوصله‌اى هستند و روى درک و يادگيرى مطلب بسيار كوشا، ارتباط بسيار خوبى برقرار
        می‌كنند و <strong>براى بچه‌هایی كه تفاوت زبان و فرهنگى دارن يادگيرى رو آسون می‌كنن</strong>، مرسى از درسون كه
        اين امكان رو براى ما پيش آورده.
      </Fa>
    </TestomonialCard>
  );
}

function Testomonial3() {
  const { locale } = useRouter();

  return (
    <TestomonialCard
      name={locale === "en" ? "Kaveh & Andrea" : "شادی"}
      gridRow="1/3"
      gridColumn="22/30"
      bg="bg-[#F8DEDE]"
      gradientTo="to-[#F8DEDE]"
      gradientFrom="from-[#FEDBC2]/70"
      color="c-[#F8DEDE]"
      from={locale === "en" ? "Canada" : "سیاتل"}
      subject={locale === "en" ? "Fitness" : "اکسل پیشرفته"}
    >
      <En>
        She is a great fitness instructor who has helped us with rehabilitation and strengthening.{" "}
        <strong>Over the many months we see clear improvement in our fitness</strong> and looking forward to continuing
        our sessions.
      </En>
      <Fa>
        معلم من بسیار صبور‌ هستند و مطالب رو‌ خیلی خوب تدریس می‌کنند و پاسخگوی همه سوالات هستند.{" "}
        <strong>خیلی راحت برای کلاس‌ها باهاشون هماهنگ می‌کنم‌</strong> و همه موارد کاربردی در کارم که حسابداری هست رو
        یاد گرفتم‌ ازشون و اعتمادبه‌نفس بالایی پیدا کردم در زمینه اکسل، همچنان دوست دارم که بیشتر یاد بگیرم ازشون و سطح
        کارم رو بالا ببرم.
      </Fa>
    </TestomonialCard>
  );
}

function Testomonial4() {
  const { locale } = useRouter();

  return (
    <TestomonialCard
      name={locale === "en" ? "Maryam" : "مهسا"}
      gridRow="3/5"
      gridColumn="10/18"
      bg="bg-[#DEE1FF]"
      gradientTo="to-[#DEE1FF]"
      gradientFrom="from-indigo4/70"
      color="c-[#DEE1FF]"
      from={locale === "en" ? "Australia" : "تورنتو"}
      subject={locale === "en" ? "Programming" : "فرانسه"}
    >
      <En>
        His teaching method is awesome and very clear.{" "}
        <strong>He&apos;s made programming so easy to understand.</strong> I recommend him to anyone struggling with
        Python.
      </En>
      <Fa>
        دختر شش ساله من <strong>روزهایی که کلاس فرانسه داره با ذوق از خواب بیدار می‌شه</strong> و بعد از کلاس هم کلی
        انرژی داره .در مدت ۵ جلسه خیلی مطلب یاد گرفته و دوست داره کارتون‌های فرانسه ببینه.
      </Fa>
    </TestomonialCard>
  );
}

function Testomonial5() {
  const { locale } = useRouter();

  return (
    <TestomonialCard
      name={locale === "en" ? "Aida" : "آسیه"}
      gridRow="3/5"
      gridColumn="18/30"
      bg="bg-[#FBEBC7]"
      gradientTo="to-[#FBEBC7]"
      gradientFrom="from-bronze4/70"
      color="c-[#FBEBC7]"
      from={locale === "en" ? "Canada" : "استرالیا"}
      subject={locale === "en" ? "Math" : "پیانو"}
      className="lt-lg:pie-4"
    >
      <En>
        <strong>He is really good at making connections with the kids.</strong> He is also very dedicated to his work
        and I like that he sends some homework so my daughter always has something to practice
      </En>
      <Fa>
        ایشون خیلی سریع تونستن با پسر من ارتباط برقرار کنند و{" "}
        <strong>رو آهنگی که دوست داشت موارد درسی‌شون را یاد بدن</strong>. کلاس‌ها خیلی منظم و بابرنامه هست همیشه. پسر من
        فعلاً بدون اینکه من بگم دوست داره تمرین پیانو بکنه!
      </Fa>
    </TestomonialCard>
  );
}
